<template>
  <form class="flex flex-wrap mx-0" @submit.prevent="createChannel">
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: v$.inboxName.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
        <input
          v-model.trim="inboxName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
          @blur="v$.inboxName.$touch"
        />
        <span v-if="v$.inboxName.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
        </span>
      </label>
    </div>

    <div class="w-full flex gap-4">
      <woot-submit-button
        :loading="uiFlags.isCreating || registeringNumber"
        :disabled="uiFlags.isCreating || registeringNumber"
        v-if="canGoToNextStep"
        :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
      />
      <button
        type="button"
        v-if="inboxName && !canGoToNextStep"
        @click="launchSignupFlow"
        :disabled="loading"
        class="button nice gap-2 !bg-[var(--color-whatsapp-brand)]"
      >
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.EMBEDDED_SIGNUP.BUTTON_TEXT') }}
      </button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import { required } from '@vuelidate/validators';
import router from '../../../../index';
import { isPhoneE164OrEmpty, isNumber } from 'shared/helpers/Validators';
import { loadScript } from 'dashboard/helper/DOMHelpers';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      inboxName: '',
      phoneNumber: '',
      apiKey: '',
      phoneNumberId: '',
      businessAccountId: '',
      canGoToNextStep: false,
      registeringNumber: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags', currentAccountId: 'getCurrentAccountId' }),
  },
  validations: {
    inboxName: { required },
    phoneNumber: { required, isPhoneE164OrEmpty },
    apiKey: { required },
    phoneNumberId: { required, isNumber },
    businessAccountId: { required, isNumber },
  },
  async mounted() {
    await this.loadFBsdk();
    FB.init({
      appId            : chatwootConfig.metaAppId,
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v21.0'
    });

    this.setupFacebookListener();

  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleFacebookMessage);
  },
  methods: {
    async loadFBsdk() {
      return loadScript('https://connect.facebook.net/en_US/sdk.js', {
        id: 'embedded-signup-jssdk',
      });
    },
    setupFacebookListener() {
      window.addEventListener('message', this.handleFacebookMessage);
    },

    handleFacebookMessage(event) {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }

      try {
        const data = JSON.parse(event.data);

        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;

            this.phoneNumberId = phone_number_id;
            this.businessAccountId = waba_id;
          }

          if (data.event === 'ERROR') {
            console.error("Signup Error:", data.data.error_message);
          }
        }
      } catch (error) {
        console.error('Non-JSON Response:', event.data);
      }
    },

    async fetchPhoneNumber() {
      try {
        const response = await axios.get(
          `https://graph.facebook.com/v21.0/${this.phoneNumberId}`,
          {
            headers: {
              Authorization: `Bearer ${this.apiKey}`,
            },
          }
        );

        let { display_phone_number } = response.data;
        this.phoneNumber = this.formatToE164(display_phone_number);
      } catch (error) {
        this.canGoToNextStep = false;
        useAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.FETCH_ERROR'));
      }
    },

    formatToE164(phoneNumber) {
      return phoneNumber.replace(/[\s()-]/g, '');
    },

    async launchSignupFlow() {
      try {
        this.loading = true;
        const response = await new Promise((resolve, reject) => {
          FB.login(async (res) => {
            if (res.authResponse) {
              const code = res.authResponse?.code;

              try {
                await this.exchangeCodeWithBackend(code);
                await this.fetchPhoneNumber();
                this.canGoToNextStep = true;
                resolve();
              } catch (err) {
                reject(err);
              }
            } else {
              reject();
            }
          },
          {
            config_id: chatwootConfig.metaEsConfig,
            response_type: 'code',
            override_default_response_type: true,
            extras: {
              sessionInfoVersion: '2',
            }
          });
        });
      } catch (error) {
        useAlert(error?.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'));
      } finally {
        this.loading = false;
      }
    },

    async exchangeCodeWithBackend(code) {
      try {
        const response = await axios.post(`/api/v1/accounts/${this.currentAccountId}/whatsapp/authentication/exchange_code`, {
          code,
        });

        const { data = {} } = response;

        this.apiKey = data?.access_token || '';
      } catch (error) {
        console.error('Error exchanging code:', error);
        useAlert(error?.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'));
      }
    },

    async registerNumber() {
      try {
        await axios.post(`/api/v1/accounts/${this.currentAccountId}/whatsapp/register_number/${this.phoneNumber}`);
      } catch (error) {
        console.error('Error registering number:', error);
        useAlert(error?.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'));
      }
    },

    async createChannel() {
      this.v$.$touch();
      this.loading = true;
      if (this.v$.$invalid) return;

      try {
        const whatsappChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.inboxName,
          channel: {
            type: 'whatsapp',
            phone_number: this.phoneNumber,
            provider: 'whatsapp_cloud',
            provider_config: {
              api_key: this.apiKey,
              phone_number_id: this.phoneNumberId,
              business_account_id: this.businessAccountId,
              created_with_embedded_signup: true,
            },
          },
        });

        this.registeringNumber = true;
        await this.registerNumber();
        this.registeringNumber = false;

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: { page: 'new', inbox_id: whatsappChannel.id },
        });
      } catch (error) {
        this.registeringNumber = false;
        useAlert(error.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

